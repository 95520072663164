import React from "react"
import { Layout } from "../components"
import { GatsbySeo } from "gatsby-plugin-next-seo"
// import { graphql, useStaticQuery } from "gatsby"
//
// const query = graphql`
//   query GetAllPagesQuery {
// pages: allSitePage {
//   nodes {
// path
//   }
// }
//   }
// `

// {
// pages.allFile.nodes.map((page, index) => {
// return (
// <div key="{index}" className="">
// {/* {page.path} */}
//{/* </div> */}
// )
// })
// }

const Error = () => {
  //const pages = useStaticQuery(query)
  return (
    <Layout>
      <GatsbySeo title="404: Pagina niet gevonden" noindex={true} />
      <div className="container mx-auto max-w-5xl">
        <h2>Pagina niet gevonden</h2>
        <div>
          De pagina die u zocht is niet gevonden. Gebruik het menu hierboven om
          naar de juiste pagina te gaan.
        </div>
      </div>
    </Layout>
  )
}

export default Error
